.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

* {
  font-size: 1.2rem;
}
