* {
  box-sizing: border-box;
}

button {
  background-color: mediumaquamarine;
  border: 2px solid mediumaquamarine;
  border-radius: 5px;
  color: rebeccapurple;
  display: block;
  font-family: inherit;
  font-weight: bold;
  margin-left: auto;
  padding: 0.2em 0.8em;
}

button:focus {
  border-color: rebeccapurple;
  outline: none;
  position: relative;
}

button div {
  left: 50%;
  position: absolute !important;
  top: 50%;
}

form > div {
  margin: 0 auto;
  max-width: 90vw;
  width: 300px;
}

form > div + div {
  margin-top: 1em;
}

input {
  border: 2px solid darkgray;
  border-radius: 5px;
  display: block;
  font-family: inherit;
  margin-top: 0.2em;
  padding: 0.2em 0.4em;
  width: 100%;
}
input[data-has-error=true] {
  border-color: tomato;
}

input:focus,
input[data-has-error=true]:focus {
  border-color: rebeccapurple;
  outline: none;
}


label {
  font-weight: bold;
}

p {
  color: tomato;
  font-size: 0.8em;
  margin-bottom: 0;
  margin-top: 0.2em;
}
