@font-face {
	font-family: 'TTRounds';
  font-style: normal;
  font-weight: normal;
	src: url("./fonts/ttroundscondensed-regular-webfont.woff2") format("woff2");
}

@font-face {
	font-family: 'TTRounds';
  font-style: normal;
  font-weight: bold;
	src: url("./fonts/ttroundscondensed-bold-webfont.woff2") format("woff2");
}

body {
  margin: 0;
  font-family: 'TTRounds', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
